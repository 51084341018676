import React  from "react"
import { Link } from 'gatsby'

// components
import Layout from "./Layout";

//images
import temp from '../images/temp.png';

// data 
import data from "../data/data.json"

const Template = ({ key, index }) => {
    const service = data[index];
    const personal = []
    const domestic = []
    var path;
    for(let i = 0; i < data.length; i++){
        if(i < 8){
            personal.push(<Link to={"/"+data[i].title.split(' ')[0].toLowerCase()}  activeStyle={{ background: "#ceeaf7" }}>{data[i].title}</Link>)
        }else{
            domestic.push(<Link to={"/"+data[i].title.split(' ')[0].toLowerCase()} activeStyle={{ background: "#ceeaf7" }}>{data[i].title}</Link>)
        }
    } 
    if (index < 8){
        path = `/ Our Services / Personal / ${service.title}`
    }else{
        path = `/ Our Services / Domestic / ${service.title}`
    }
    return (
       <Layout title="Our Services"  path={path}>   
            <div id="serviceTemp"> 
                <div id="disappear">
                    <div id="mininav">
                        <span>Personal Care</span>
                        {personal}
                        <span>Domestic Care</span>
                        {domestic}
                    </div>
                </div>
                <article> 
                    <h1>{service.title}</h1>
                    <img src={temp} alt="temp"></img>
                    <p>{service.description}</p>
                    <Link to="/contact" id="template-btn">Contact Us!</Link>
                </article>
            </div>
        </Layout>   
  )
}

export default Template
